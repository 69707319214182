<template>
  <v-card class="pa-4">
    <!-- Заголовок и выбор устройства -->
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Управление витринами</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-model="device"
        :items="devices"
        label="Устройство"
        dense
        outlined
        hide-details
        style="max-width: 150px"
      ></v-select>
    </v-toolbar>

    <!-- Переключение между списком и редактором -->
    <v-tabs v-model="activeTab" background-color="grey lighten-4" grow>
      <v-tab key="list">Список витрин</v-tab>
      <v-tab key="editor">Создание витрины</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <!-- ==================== СПИСОК ВИТРИН ==================== -->
      <v-tab-item key="list">
        <v-card flat class="showcase-list">
          <v-card-text>
            <!-- Выбор типа витрины -->
            <v-select
              v-model="selectedType"
              :items="localizedTypes"
              label="Тип витрины"
              dense
              outlined
              hide-details
              style="max-width: 250px"
            ></v-select>

            <v-divider class="my-4"></v-divider>

            <!-- Список витрин (draggable) -->
            <draggable
              v-model="orderedShowcases"
              @end="onShowcaseOrderChanged"
              handle=".drag-handle"
              tag="v-list"
            >
              <v-list-item
                v-for="item in orderedShowcases"
                :key="item.id"
                class="d-flex align-center showcase-item"
              >
                <!-- Зона перетаскивания (иконка) -->
                <v-list-item-action class="drag-handle" style="cursor: move;">
                  <v-icon>mdi-menu</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                  <!-- Краткий вывод категорий/продуктов -->
                  <v-list-item-subtitle
                    class="grey--text text--darken-1"
                    v-if="getSelectedItems(item)"
                  >
                    {{ getSelectedItems(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="action">
                  <!-- Кнопка Редактирования -->
                  <v-btn icon @click="editShowcase(item)" @mousedown.stop>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <!-- Кнопка Удаления (скрытия) -->
                  <v-btn icon @click="deleteShowcase(item)" @mousedown.stop>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <!-- Переключатель hidden -->
                  <div>
                    <p>Скрыть</p>
                    <v-switch
                      :input-value="item.hidden"
                      @change="(val) => toggleHidden(item, val)"
                      inset
                      hide-details
                      @mousedown.stop
                    ></v-switch>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- ==================== РЕДАКТОР ВИТРИНЫ ==================== -->
      <v-tab-item key="editor">
        <v-card flat>
          <!-- ДИНАМИЧЕСКИЙ ЗАГОЛОВОК (Редактирование/Создание) -->
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title v-if="form.id">Редактирование витрины</v-toolbar-title>
            <v-toolbar-title v-else>Создание витрины</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <!-- Общие поля -->
              <v-text-field
                v-model="form.name"
                label="Название витрины"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.title"
                label="Заголовок витрины"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.orderOnPage"
                label="Порядок (номер)"
                type="number"
                :rules="[rules.required]"
                required
              ></v-text-field>

              <!-- ============ Карусель ============ -->
              <div v-if="selectedType === 'carousel'">
                <v-autocomplete
                  v-model="selectedCategoryTemp"
                  :items="categoryList"
                  item-text="name"
                  item-value="id"
                  label="Добавить категорию"
                  clearable
                  outlined
                  dense
                  hide-details
                  @change="addCategoryToCarousel"
                ></v-autocomplete>

                <v-divider class="my-4"></v-divider>
                <div class="mb-2 font-weight-bold">
                  Выбранные категории (перетащите для изменения порядка):
                </div>
                <draggable
                  v-model="selectedCategories"
                  @end="onCarouselOrderChanged"
                  handle=".drag-handle"
                >
                  <v-list-item
                    v-for="(cat, index) in selectedCategories"
                    :key="cat.id"
                    class="px-2"
                  >
                    <v-list-item-action class="drag-handle" style="cursor: move;">
                      <v-icon>mdi-menu</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>{{ cat.name }}</v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="removeCategoryFromCarousel(index)" @mousedown.stop>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </div>

              <!-- ============ Витрины module, background, common ============ -->
              <div
                v-else-if="selectedType === 'module' 
                        || selectedType === 'background' 
                        || selectedType === 'common'"
              >
                <v-autocomplete
                  v-model="form.parentCategoryId"
                  :items="parentCategoryOptions"
                  item-text="name"
                  item-value="id"
                  label="Выберите родительскую категорию"
                  clearable
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
                <div v-if="form.parentCategoryId" class="mt-2">
                  <v-chip color="primary" dark>
                    {{ getCategoryName(form.parentCategoryId) }}
                  </v-chip>
                </div>
              </div>

              <!-- ============ Витрина promo ============ -->
              <div v-else-if="selectedType === 'promo'">
                <v-select
                  v-model="form.linkType"
                  :items="promoLinkTypes"
                  label="Тип ссылки"
                  dense
                  outlined
                  hide-details
                  style="padding-bottom: 16px;"
                ></v-select>

                <!-- Если linkType = department -->
                <div v-if="form.linkType === 'department'">
                  <v-autocomplete
                    v-model="form.parentDepartmentId"
                    :items="departmentList"
                    item-text="name"
                    item-value="id"
                    label="Выберите филиал"
                    clearable
                    outlined
                    dense
                    hide-details
                    style="padding-bottom: 16px;"
                  ></v-autocomplete>
                </div>

                <!-- Если linkType = category -->
                <div v-else-if="form.linkType === 'category'">
                  <v-autocomplete
                    v-model="form.parentCategoryId"
                    :items="parentCategoryOptions"
                    item-text="name"
                    item-value="id"
                    label="Выберите категорию"
                    clearable
                    outlined
                    dense
                    hide-details
                    style="padding-bottom: 16px;"
                  ></v-autocomplete>
                </div>

                <!-- Блок с продуктами (если филиал ИЛИ категория выбрана) -->
                <div
                  v-if="(form.linkType === 'department' && form.parentDepartmentId)
                      || (form.linkType === 'category' && form.parentCategoryId)"
                >
                  <!-- Лоадер (productsLoading) -->
                  <template v-if="productsLoading">
                    <div class="pa-4 text-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="40"
                      ></v-progress-circular>
                      <div class="mt-2">Идёт загрузка данных о товарах...</div>
                    </div>
                  </template>
                  <template v-else>
                    <!-- Автокомплит для добавления продукта -->
                    <v-autocomplete
                      v-model="selectedProductTemp"
                      :items="filteredProductList"
                      item-text="name"
                      item-value="id"
                      label="Добавить продукт"
                      clearable
                      outlined
                      dense
                      hide-details
                      @change="addProductToPromo"
                    ></v-autocomplete>

                    <div class="mb-2 font-weight-bold">
                      Выбранные продукты (перетащите для изменения порядка):
                    </div>
                    <draggable
                      v-model="selectedProducts"
                      @end="onPromoOrderChanged"
                      handle=".drag-handle"
                    >
                      <v-list-item
                        v-for="(prod, index) in selectedProducts"
                        :key="prod.id"
                        class="px-2"
                      >
                        <v-list-item-action class="drag-handle" style="cursor: move;">
                          <v-icon>mdi-menu</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          {{ prod.name }}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="removeProductFromPromo(index)" @mousedown.stop>
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </draggable>
                  </template>
                </div>
              </div>

              <!-- Кнопки сохранения/сброса -->
              <v-btn
                color="primary"
                class="mt-4"
                @click="saveShowcase"
                :disabled="!formValid"
              >
                Сохранить
              </v-btn>
              <v-btn color="grey" class="mt-4" @click="resetForm">
                Сбросить
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ShowcaseWindowManager",
  components: { draggable },

  data() {
    return {
      loading: false,
      productsLoading: false,
      device: "web",
      devices: ["web","mobile"],

      selectedType: "carousel",
      localizedTypes: [
        { value: "carousel", text: "Карусель" },
        { value: "module", text: "Модуль" },
        { value: "background", text: "Спецальные" },
        { value: "common", text: "Обычная" },
        { value: "promo", text: "Промо" }
      ],

      activeTab: 0,
      formValid: false,

      form: {
        id: null,
        name: "",
        title: "",
        orderOnPage: 1,
        linkType: "",
        parentCategoryId: null,
        parentDepartmentId: null
      },

      selectedCategories: [],
      selectedProducts: [],
      oldSelectedCategories: [],
      oldSelectedProducts: [],

      // Временные поля для добавления
      selectedCategoryTemp: null,
      selectedProductTemp: null,

      // Списки категорий/товаров/филиалов
      categoryList: [],
      productList: [],
      departmentList: [],

      // Правила валидации
      rules: {
        required: (v)=>!!v || "Обязательное поле",
      },

      // Список витрин (для drag'n'drop)
      orderedShowcases: []
    };
  },

  computed: {
    ...mapGetters({
      allCategories: "Category/ALL_CATEGORY",
      promoCategories: "Category/PROMO_CATEGORY",
      specialCategories: "Category/SPECIAL_CATEGORY",
      showcases: "ShowcaseWindow/allShowcases",
    }),

    // Если у вас есть глобальный список товаров (например paginatedProducts),
    // но здесь используем локальный productList
    allProducts() {
      return this.$store.state.Products.paginatedProducts || [];
    },
    allDepartments() {
      return this.$store.state.Department.cityDepartment || [];
    },

    promoLinkTypes() {
      return [
        { value: "department", text: "Филал" },
        { value: "category", text: "Категория" },
      ];
    },

    sortedShowcases() {
      return [...this.showcases].sort((a,b)=>{
        const posA = a.position || a.orderOnPage || 0;
        const posB = b.position || b.orderOnPage || 0;
        return posA - posB;
      });
    },

    /**
     * parentCategoryOptions() — для module/background/common/promo(category)
     */
    parentCategoryOptions() {
      if (this.selectedType==="promo" && this.form.linkType==="category") {
        return this.promoCategories || [];
      }
      if (this.selectedType==="background") {
        return this.specialCategories || [];
      }
      return this.categoryList;
    },

    /**
     * filteredProductList:
     *   - Если linkType=department => server уже отдаёт нужные товары
     *   - Если linkType=category => server уже отдаёт нужные товары
     */
    filteredProductList() {
      if (this.selectedType!=="promo") return [];
      if (this.form.linkType==="department" && this.form.parentDepartmentId) {
        return this.productList;
      }
      if (this.form.linkType==="category" && this.form.parentCategoryId) {
        return this.productList;
      }
      return [];
    },
  },

  watch: {
    device() {
      // При смене устройства — запрашиваем данные
      this.fetchData();
    },
    selectedType() {
      // При смене типа витрины — сбрасываем форму и обновляем витрины
      this.resetForm();
      this.fetchShowcases();
    },

    // Когда Vuex обновит категории/товары/филиалы, подтянем их в локальные массивы
    allCategories(newVal) {
      this.categoryList=newVal;
    },
    allProducts(newVal) {
      this.productList=newVal;
    },
    allDepartments(newVal) {
      this.departmentList=newVal;
    },

    // Когда Vuex обновит showcases, сортируем их в orderedShowcases
    showcases: {
      handler(newVal) {
        this.orderedShowcases=[...newVal].sort((a,b)=>{
          const posA=a.position||a.orderOnPage||0;
          const posB=b.position||b.orderOnPage||0;
          return posA-posB;
        });
      },
      immediate:true
    },

    // При выборе филиала => загружаем товары
    "form.parentDepartmentId"(newVal,oldVal) {
      if (newVal) {
        const dep=this.departmentList.find(d=>d.id===newVal);
        console.log("Выбранный филиал:", dep);
      }
      if (this.selectedType==="promo" && this.form.linkType==="department" && newVal) {
        console.log("Запрашиваем товары по филиалу ID=", newVal);
        this.productsLoading=true;
        this.$store.dispatch("Products/GET_PRODUCTS_BY_DEPARTMENT", newVal)
          .then(data=>{
            console.log("Продукты, полученные для филиала:", data);
            this.productsLoading=false;
            this.productList=data;
          })
          .catch(err=>{
            this.productsLoading=false;
            console.error("Ошибка загрузки товаров:", err);
          });
      }
    },

    // Логируем выбор продукта (Autocomplete)
    selectedProductTemp(newVal) {
      if(newVal){
        const prod=this.productList.find(p=>p.id===newVal);
        console.log("Выбран продукт:", prod);
      }
    },

    // При выборе категории => загружаем товары
    "form.parentCategoryId"(newVal,oldVal) {
      if(this.selectedType==="promo" && this.form.linkType==="category" && newVal){
        console.log("Запрашиваем товары по категории ID=", newVal);
        this.productsLoading=true;
        this.$store.dispatch("Products/GET_CATEGORY_PRODUCTS", newVal)
          .then(data=>{
            console.log("Продукты для категории:", data);
            this.productsLoading=false;
            this.productList=data;
          })
          .catch(err=>{
            this.productsLoading=false;
            console.error("Ошибка загрузки товаров:", err);
          });
      }
    },

    // При уходе на вкладку списка (activeTab=0) сбрасываем форму
    activeTab(newVal,oldVal) {
      if(oldVal===1 && newVal===0){
        this.resetForm();
      }
    }
  },

  methods: {
    // Экшны Vuex для витрин
    ...mapActions({
      fetchShowcaseWindow: "ShowcaseWindow/fetchShowcaseWindow",
      createShowcaseWindow: "ShowcaseWindow/createShowcaseWindow",
      editShowcaseWindow: "ShowcaseWindow/editShowcaseWindow",
      deleteShowcaseCategory: "ShowcaseWindow/deleteShowcaseCategory",
      deleteShowcaseProduct: "ShowcaseWindow/deleteShowcaseProduct",
      updateShowcaseCategoriesOrder: "ShowcaseWindow/updateShowcaseCategoriesOrder",
      updateShowcaseProductsOrder: "ShowcaseWindow/updateShowcaseProductsOrder",
      addShowcaseCategory: "ShowcaseWindow/addShowcaseCategory",
      addShowcaseProduct: "ShowcaseWindow/addShowcaseProduct",
      updateShowcasePositions: "ShowcaseWindow/updateShowcasePositions",
    }),

    // Изначальная загрузка
    fetchData() {
      this.categoryList=this.allCategories;
      this.productList=this.allProducts;
      this.departmentList=this.allDepartments;
      this.fetchShowcases();
    },
    fetchShowcases() {
      this.fetchShowcaseWindow({ device:this.device, type:this.selectedType });
    },

    // Отобразить выбранные категории/продукты (в списке витрин)
    getSelectedItems(item) {
      if(item.type==="carousel" && Array.isArray(item.categories)){
        const sorted=item.categories.slice().sort((a,b)=>(a.orderOnWindow||0)-(b.orderOnWindow||0));
        return sorted.map(c=>c.name).join(", ");
      }
      else if(item.type==="promo" && Array.isArray(item.products)){
        const sorted=item.products.slice().sort((a,b)=>(a.orderOnWindow||0)-(b.orderOnWindow||0));
        return sorted.map(p=>p.name).join(", ");
      }
      else if(["module","background","common"].includes(item.type) && item.parentCategory){
        return item.parentCategory.name;
      }
      return "";
    },

    getCategoryName(categoryId) {
      const cat=this.categoryList.find(c=>c.id===categoryId);
      return cat?cat.name:"";
    },

    // Добавить категорию в карусель
    addCategoryToCarousel() {
      if(!this.selectedCategoryTemp)return;
      const exists=this.selectedCategories.find(c=>c.id===this.selectedCategoryTemp);
      if(!exists){
        const cat=this.categoryList.find(c=>c.id===this.selectedCategoryTemp);
        if(cat)this.selectedCategories.push(cat);
      }
      this.selectedCategoryTemp=null;
    },
    removeCategoryFromCarousel(index){
      this.selectedCategories.splice(index,1);
    },
    onCarouselOrderChanged(){},

    // Добавить продукт в promo
    addProductToPromo() {
      if(!this.selectedProductTemp)return;
      const exists=this.selectedProducts.find(p=>p.id===this.selectedProductTemp);
      if(!exists){
        const prod=this.productList.find(p=>p.id===this.selectedProductTemp);
        if(prod)this.selectedProducts.push(prod);
      }
      this.selectedProductTemp=null;
    },
    removeProductFromPromo(index){
      this.selectedProducts.splice(index,1);
    },
    onPromoOrderChanged(){},

    // Скрыть/показать витрину
    async toggleHidden(item,newHidden){
      const payload={
        id:item.id,
        hidden:newHidden,
        name:item.name,
        title:item.title
      };
      try{
        await this.editShowcaseWindow(payload);
        this.$notify({
          group:"app",
          type:"success",
          title:"Успех",
          text:"Статус витрины обновлён"
        });
        this.fetchShowcases();
      }catch(e){
        console.error(e);
        this.$notify({
          group:"app",
          type:"error",
          title:"Ошибка",
          text:"Не удалось обновить статус витрины"
        });
      }
    },

    // Сохранить витрину (создать/редактировать)
    async saveShowcase(){
      this.$refs.form.validate();
      if(!this.formValid) return;

      // Создание
      if(!this.form.id){
        const payload={
          name:this.form.name,
          title:this.form.title,
          device:this.device,
          type:this.selectedType,
          orderOnPage:+this.form.orderOnPage,
          linkType:null,
          parentCategoryId:null,
          parentDepartmentId:null,
          categories:null,
          products:null
        };
        if(this.selectedType==="carousel"){
          let catMap={};
          this.selectedCategories.forEach((c,i)=>{
            catMap[c.id]=i+1;
          });
          payload.categories=catMap;
        }
        else if(["module","background","common"].includes(this.selectedType)){
          payload.parentCategoryId=this.form.parentCategoryId;
        }
        else if(this.selectedType==="promo"){
          payload.linkType=this.form.linkType;
          payload.parentDepartmentId=this.form.parentDepartmentId;
          payload.parentCategoryId=this.form.parentCategoryId;
          let prodMap={};
          this.selectedProducts.forEach((p,i)=>{
            prodMap[p.id]=i+1;
          });
          payload.products=prodMap;
        }
        try {
          await this.createShowcaseWindow(payload);
          this.$notify({
            group:"app",
            type:"success",
            title:"Успех",
            text:"Витрина создана"
          });
          this.resetForm();
          this.fetchShowcases();
          this.activeTab=0;
        } catch(e){
          console.error(e);
          this.$notify({
            group:"app",
            type:"error",
            title:"Ошибка",
            text:"Не удалось создать витрину"
          });
        }
      }
      // Редактирование
      else {
        try{
          const updatePayload={
            id:this.form.id,
            hidden:false,
            device:this.device,
            type:this.selectedType,
            name:this.form.name,
            title:this.form.title,
            orderOnPage:+this.form.orderOnPage
          };
          if(this.selectedType==="promo"){
            updatePayload.linkType=this.form.linkType;
            updatePayload.parentDepartmentId=this.form.parentDepartmentId;
            updatePayload.parentCategoryId=this.form.parentCategoryId;
          }
          else if(["module","background","common"].includes(this.selectedType)){
            updatePayload.parentCategoryId=this.form.parentCategoryId;
          }
          await this.editShowcaseWindow(updatePayload);

          // Если карусель => синхронизируем категории
          if(this.selectedType==="carousel"){
            const removedCats=this.oldSelectedCategories.filter(oldC=>
              !this.selectedCategories.some(newC=>newC.id===oldC.id)
            );
            for(let cat of removedCats){
              await this.deleteShowcaseCategory(cat.id);
            }
            const addedCats=this.selectedCategories.filter(newC=>
              !this.oldSelectedCategories.some(oldC=>oldC.id===newC.id)
            );
            for(let cat of addedCats){
              await this.addShowcaseCategory({ windowId:this.form.id, categoryId:cat.id });
            }
            const orderPayload=this.selectedCategories.map((c,i)=>({
              id:c.id, position:i+1
            }));
            await this.updateShowcaseCategoriesOrder(orderPayload);
          }

          // Если promo => синхронизируем продукты
          if(this.selectedType==="promo"){
            const removedProds=this.oldSelectedProducts.filter(oldP=>
              !this.selectedProducts.some(newP=>newP.id===oldP.id)
            );
            for(let p of removedProds){
              await this.deleteShowcaseProduct(p.id);
            }
            const addedProds=this.selectedProducts.filter(newP=>
              !this.oldSelectedProducts.some(oldP=>oldP.id===newP.id)
            );
            for(let p of addedProds){
              await this.addShowcaseProduct({ windowId:this.form.id, productId:p.id });
            }
            const orderPayload=this.selectedProducts.map((p,i)=>({
              id:p.id, position:i+1
            }));
            await this.updateShowcaseProductsOrder(orderPayload);
          }

          this.$notify({
            group:"app",
            type:"success",
            title:"Успех",
            text:"Витрина обновлена"
          });
          this.resetForm();
          this.fetchShowcases();
          this.activeTab=0;
        } catch(e){
          console.error(e);
          this.$notify({
            group:"app",
            type:"error",
            title:"Ошибка",
            text:"Не удалось обновить витрину"
          });
        }
      }
    },

    // Редактировать существующую витрину
    editShowcase(item){
      this.selectedType=item.type;
      this.form.id=item.id;
      this.form.name=item.name;
      this.form.title=item.title;
      this.form.orderOnPage=(item.orderOnPage!==undefined ? item.orderOnPage : 1);

      // ВАЖНО: у вас в parentDepartment лежит { departmentId, name }, берём departmentId
      this.form.parentDepartmentId=
        item.parentDepartment && item.parentDepartment.departmentId
        ? item.parentDepartment.departmentId
        : null;

      // Если есть parentCategory => берём parentCategory.id
      this.form.parentCategoryId=
        item.parentCategory
        ? item.parentCategory.id
        : null;

      this.form.linkType=item.linkType||"";

      if(this.selectedType==="carousel"){
        if(Array.isArray(item.categories)){
          const sortedCats=[...item.categories].sort((a,b)=>(a.orderOnWindow||0)-(b.orderOnWindow||0));
          this.selectedCategories=sortedCats;
          this.oldSelectedCategories=JSON.parse(JSON.stringify(sortedCats));
        } else {
          this.selectedCategories=[];
          this.oldSelectedCategories=[];
        }
      } else {
        this.selectedCategories=[];
        this.oldSelectedCategories=[];
      }

      if(this.selectedType==="promo"){
        if(Array.isArray(item.products)){
          const sortedProds=[...item.products].sort((a,b)=>(a.orderOnWindow||0)-(b.orderOnWindow||0));
          this.selectedProducts=sortedProds;
          this.oldSelectedProducts=JSON.parse(JSON.stringify(sortedProds));
        } else {
          this.selectedProducts=[];
          this.oldSelectedProducts=[];
        }
      } else {
        this.selectedProducts=[];
        this.oldSelectedProducts=[];
      }
      this.activeTab=1;
    },

    resetForm(){
      this.form={
        id:null,
        name:"",
        title:"",
        orderOnPage:1,
        linkType:"",
        parentCategoryId:null,
        parentDepartmentId:null
      };
      this.selectedCategories=[];
      this.selectedProducts=[];
      this.oldSelectedCategories=[];
      this.oldSelectedProducts=[];
      this.productsLoading=false;
      if(this.$refs.form) this.$refs.form.reset();
    },

    async deleteShowcase(item){
      if(!confirm("Удалить витрину?"))return;
      try{
        const deletePayload={
          id:item.id,
          hidden:true,
          name:item.name,
          title:item.title
        };
        await this.editShowcaseWindow(deletePayload);
        this.$notify({
          group:"app",
          type:"success",
          title:"Успех",
          text:"Витрина удалена (скрыта)",
        });
        this.fetchShowcases();
      }catch(e){
        console.error(e);
        this.$notify({
          group:"app",
          type:"error",
          title:"Ошибка",
          text:"Не удалось удалить витрину",
        });
      }
    },

    async onShowcaseOrderChanged(){
      this.orderedShowcases.forEach((item,index)=>{
        item.position=index+1;
      });
      const payload=this.orderedShowcases.map(item=>({
        id:item.id,
        position:item.position
      }));
      try{
        await this.updateShowcasePositions(payload);
        this.$notify({
          group:"app",
          type:"success",
          title:"Порядок обновлен",
          text:"Порядок витрин успешно сохранен.",
        });
      }catch(e){
        console.error("Ошибка обновления порядка витрин:",e);
        this.$notify({
          group:"app",
          type:"error",
          title:"Ошибка",
          text:"Не удалось обновить порядок витрин.",
        });
      }
    }
  },

  mounted(){
    if(!this.allCategories||!this.allCategories.length){
      this.$store.dispatch("Category/GET_ALL_CATEGORY");
    }
    this.$store.dispatch("Category/GET_PROMO_CATEGORY");
    this.$store.dispatch("Category/GET_SPECIAL_CATEGORY");

    // Если нужно, запрашиваем товары или филиалы
    // ...

    this.fetchData();
  }
};
</script>

<style scoped>
.showcase-item {
  background-color: aliceblue;
  border-bottom: 0.5px solid #000;
}
.action {
  flex-direction: row;
}
.drag-handle {
  margin-right: 8px;
}
</style>
